(function () {
  if (window._fitAnalytics) return;

  window._fitAnalytics = function (imports) {
    const { Integration, _ } = imports;

    const labelEvent = (event) => {
      const eventToLabelMapping = {
        'add to cart': 'add to cart',
        'fitfinder open': 'fitfinder open',
        'sizeChart open': 'sizeChart open'
      };
      return eventToLabelMapping[event];
    };

    const pushDataLayerEvent = (widgetEvent, data) => {
      const eventLabel = labelEvent(widgetEvent),
        dataLayer = window.dataLayer || [];
      if (eventLabel) {
        if (data)
          dataLayer.push({
            event: 'fitfinder',
            domevent: eventLabel,
            domlabel: data
          });
        else
          dataLayer.push({
            event: 'fitfinder',
            domevent: eventLabel
          });
      }
    };

    const integration = new Integration({
      shop: {
        node: window,
        prefix: 'puma_in-',
        language: {
          path: 'fitAnalytics.shopLanguage'
        },
        country: {
          path: 'fitAnalytics.shopCountry'
        },
        userId: {
          path: 'fitAnalytics.userId'
        },
        shopSessionId: {
          cookie: '_ga'
        },
        rootDomain: {
          node: window,
          path: 'location.hostname'
        },
        isReady: () => !!window.fitAnalytics
      },

      globalEvents: {
        product_load: () => {
          // const styleTag = document.createElement('style')
          // styleTag.textContent = `
          //   .fitanalytics__button::after {
          //     content: "|";
          //     font-size: 12px;
          //     margin: 3px 5px 0 0;
          //   }`
          // document.querySelector('.fitanalytics__button')?.append(styleTag)
        },
        fit_finder_open: () => pushDataLayerEvent('fitfinder open'),
        add_to_cart: () => {
          pushDataLayerEvent('add to cart');
        },
        metric_event: (name, value) => {
          if (value === 'sizeChart' && name === 'clickButton') pushDataLayerEvent('sizeChart open');
        }
      },

      scope: '#puma-skip-here',

      product: {
        container: 'section[aria-labelledby="pdp-product-title"]',

        isLoaded: () => {
          let breadcrumb = document.getElementById('breadcrumb')?.textContent.toLowerCase();
          return !(breadcrumb?.includes('kids') && breadcrumb?.includes('shoes'));
        },

        hasManufacturedSizes: true,

        serials: {
          node: window,
          path: 'fitAnalytics.allProductIds'
        },

        currentSerial: {
          node: window,
          path: 'fitAnalytics.currentProductId'
        },

        thumbnail: {
          node: window,
          path: 'fitAnalytics.mainThumbnail'
        },

        addToCart: (serial, size) => {
          const serialNoPrefix = (serial?.split('-') || [])[1] || '',
            addToCart = _.lookup(window, 'fitAnalytics.operations.addToCart', () => {});
          addToCart(serialNoPrefix, size);
        },

        sizes: {
          node: window,
          items: {
            path: 'fitAnalytics.sizes.0.values'
          },
          code: {
            path: '.'
          },
          isAvailable: {
            path: '.',
            process: (el) => _.lookup(window, 'fitAnalytics.sizes.0.availability', [])[el]
          }
        }
      },

      button: {
        anchor: '#size-picker',
        anchorPlacement: 'after',
        imageSrc: '//integrations.fitanalytics.com/shop/puma_in/hanger-pdp-50x31-black.png',
        // imageSrc: '//localhost:3018/hanger-pdp-50x31-black.png',
        text: {
          en: ['Find My Size', 'Your size is {{size}}'],
          fr: ['Trouver ma taille', 'Votre taille est {{size}}'],
          es: ['Encuentre mi talla', 'Tu talla es {{size}}']
        },
        style: {
          button: {
            'margin-left': 'auto',
            'margin-top': '16px',
            height: '24px',
            display: 'flex',
            'text-transform': 'uppercase',
            'text-decoration': 'none',
            'margin-bottom': 'unset',
            cursor: 'pointer',
            'line-height': '1rem'
          },
          text: {
            color: '#181818',
            'font-size': '0.75rem',
            'font-weight': '700',
            'margin-right': '5px'
          },
          image: {
            color: '#181818',
            width: '20px',
            height: '12px',
            'margin-top': '6px',
            'margin-right': '3px'
          }
        },
        buttonAttrs: {
          role: 'button',
          tabindex: '0',
          onfocus: 'this.style.outline = "none"; return false'
        }
      },

      sizeChartButton: {
        elem: 'button.size-chart-button',
        shouldHide: false
      }
    });

    window._fitAnalytics.integration = integration;
    integration.start();
  };

  function init() {
    // add the widget code to the page, once it loads
    const scriptElem = document.createElement('script');
    scriptElem.setAttribute('src', '//widget.fitanalytics.com/widget_pdp.js');
    scriptElem.setAttribute('type', 'text/javascript');
    document.body.appendChild(scriptElem);
  }
  // Under some circumstances, our script may be loaded before the document is ready
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      init();
    });
  }
})();
